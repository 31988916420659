'use client'

import { useEffect, useMemo, useRef, useState } from 'react'
import clsx from 'clsx'
import { useInView } from 'framer-motion'
import Link from 'next/link'
import { Button } from './Button'

import { Container } from '@/components/Container'

interface Review {
  title: string
  body: string
  subject: string

  price: string
  rating: 1 | 2 | 3 | 4 | 5
}

const reviews: Array<Review> = [
  {
    title: 'Builder fitted kitchen cabinets.',
    subject: 'Other.',
    body: 'Very good value for money.',

    rating: 5,
    price: '$390',
  },
  {
    title: 'It really works. He turned up on time.',
    subject: 'Plumber fixed pipe.',
    body: 'Bathroom leak sorted, the plumber actually turned up in half an hour.',

    rating: 5,
    price: '$58.95',
  },
  {
    title: 'Cleartrade did a great job.',
    subject: 'Kitchen worktop replacement.',
    body: 'I didn’t really understand how it worked until I booked my kitchen worktop replacement. The installers were polite and did a great job.',

    rating: 5,
    price: '$588',
  },
  {
    title: 'The live tracking feature was really good.',
    subject: 'Electric socket not working.',
    body: "The electrician turned up exactly on time and for once I didn't have to stay in all day.",

    rating: 4,
    price: '$75',
  },
  {
    title: 'The whole Cleartrade experience was seamless.',
    subject: 'Kitchen tap replacement.',
    body: 'This was the first time I used an app to get a quote and book a service to fix a leaking pipe without even speaking to anyone on the phone once.',

    rating: 5,
    price: '$58',
  },
  {
    title: 'Thank you!',
    subject: 'Ring doorbell installation.',
    body: 'New Ring doorbell installed and my doorbell upgraded to 16V! Will use you guys again next time.',

    rating: 5,
    price: '$75',
  },
  {
    title: 'Roof tiles replaced and moss cleaned.',
    subject: 'Roof tiles fixed.',
    body: 'If only all roofing and repair companies were like Cleartrade. Great job.',

    rating: 5,
    price: '$760',
  },
  {
    title: "Couldn't have been better.",
    subject: 'Bosch gas stove fitted.',
    body: 'First time a workman has been on-time for a while. The location tracking was really good.',

    rating: 5,
    price: '$125',
  },
  {
    title: 'Reasonably priced and completed quickly before 5pm',
    subject: 'Painted walls and ceiling.',
    body: 'At first I thought it was a bit expensive but it turned out to be the best quote and one of the few who would estimate the price of the job before coming to my house. I also needed the job done before 5pm so was happy at how fast Charles was.',

    rating: 5,
    price: '$480',
  },
  {
    title: 'Fixed my leaking pipe issue.',
    subject: 'Kitchen pipe replacement.',
    body: 'I thought the video quotation was really quite something else. Technology is really changing how people work!',

    rating: 5,
    price: '$75',
  },
]

function StarIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg viewBox="0 0 20 20" aria-hidden="true" {...props}>
      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
    </svg>
  )
}

function StarRating({ rating }: { rating: Review['rating'] }) {
  return (
    <div className="flex">
      {[...Array(5).keys()].map((index) => (
        <StarIcon key={index} className={clsx('h-5 w-5', rating > index ? 'fill-cyan-500' : 'fill-gray-300')} />
      ))}
    </div>
  )
}

function Review({
  title,
  body,
  subject,
  price,
  rating,
  className,
  ...props
}: Omit<React.ComponentPropsWithoutRef<'figure'>, keyof Review> & Review) {
  let animationDelay = useMemo(() => {
    let possibleAnimationDelays = ['0s', '0.1s', '0.2s', '0.3s', '0.4s', '0.5s']
    return possibleAnimationDelays[Math.floor(Math.random() * possibleAnimationDelays.length)]
  }, [])

  return (
    <figure
      className={clsx('animate-fade-in rounded-3xl bg-gray-50 p-6 opacity-0', className)}
      style={{ animationDelay }}
      {...props}
    >
      <blockquote className="text-gray-900">
        <StarRating rating={rating} />
        <p className="mt-4 font-semibold leading-6 before:content-['“'] after:content-['”']">{title}</p>
        <p className="text-md mt-3 leading-7 text-blue-800">
          Service: {subject} Price {price}
        </p>

        <p className="mt-3 text-base leading-7">{body}</p>
      </blockquote>
    </figure>
  )
}

function splitArray<T>(array: Array<T>, numParts: number) {
  let result: Array<Array<T>> = []
  for (let i = 0; i < array.length; i++) {
    let index = i % numParts
    if (!result[index]) {
      result[index] = []
    }
    result[index].push(array[i])
  }
  return result
}

function ReviewColumn({
  reviews,
  className,
  reviewClassName,
  msPerPixel = 0,
}: {
  reviews: Array<Review>
  className?: string
  reviewClassName?: (reviewIndex: number) => string
  msPerPixel?: number
}) {
  let columnRef = useRef<React.ElementRef<'div'>>(null)
  let [columnHeight, setColumnHeight] = useState(0)
  let duration = `80000ms`

  useEffect(() => {
    if (!columnRef.current) {
      return
    }

    let resizeObserver = new window.ResizeObserver(() => {
      setColumnHeight(columnRef.current?.offsetHeight ?? 0)
    })

    resizeObserver.observe(columnRef.current)

    return () => {
      resizeObserver.disconnect()
    }
  }, [])

  return (
    <div
      ref={columnRef}
      className={clsx('animate-marquee space-y-8 py-4', className)}
      style={{ '--marquee-duration': duration } as React.CSSProperties}
    >
      {reviews.concat(reviews).map((review, reviewIndex) => (
        <Review
          key={reviewIndex}
          aria-hidden={reviewIndex >= reviews.length}
          className={reviewClassName?.(reviewIndex % reviews.length)}
          {...review}
        />
      ))}
    </div>
  )
}

function ReviewGrid() {
  let containerRef = useRef<React.ElementRef<'div'>>(null)
  let isInView = useInView(containerRef, { once: true, amount: 0.4 })
  let columns = splitArray(reviews, 3)
  let column1 = columns[0]
  let column2 = columns[1]
  let column3 = splitArray(columns[2], 2)

  return (
    <div
      ref={containerRef}
      className="relative -mx-4 mt-16 grid h-[20rem] max-h-[150vh] grid-cols-1 items-start gap-8 overflow-hidden px-4 sm:mt-20 md:grid-cols-2 lg:grid-cols-3"
    >
      {isInView && (
        <>
          <ReviewColumn
            reviews={[...column1, ...column3.flat(), ...column2]}
            reviewClassName={(reviewIndex) =>
              clsx(
                reviewIndex >= column1.length + column3[0].length && 'md:hidden',
                reviewIndex >= column1.length && 'lg:hidden',
              )
            }
            msPerPixel={10}
          />
          <ReviewColumn
            reviews={[...column2, ...column3[1]]}
            className="hidden md:block"
            reviewClassName={(reviewIndex) => (reviewIndex >= column2.length ? 'lg:hidden' : '')}
            msPerPixel={15}
          />
          <ReviewColumn reviews={column3.flat()} className="hidden lg:block" msPerPixel={10} />
        </>
      )}
      <div className="pointer-events-none absolute inset-x-0 top-0 h-32 bg-gradient-to-b" />
      <div className="pointer-events-none absolute inset-x-0 bottom-0 h-32 bg-gradient-to-t" />
    </div>
  )
}

export function Reviews() {
  return (
    <section id="reviews" aria-labelledby="reviews-title" className="pb-16 pt-8 sm:pb-24 sm:pt-8">
      <Container>
        <h2 id="reviews-title" className="text-3xl font-bold tracking-tight text-blue-800 sm:text-center">
          Experience the &quot;Cleartrade difference.&quot;
        </h2>

        {/* <ReviewGrid />
        <p className="mt-8 text-center font-semibold text-blue-800">
          <Button variant="outline" href="/reviews">
            Read all
          </Button>
        </p> */}

        <div className="mt-16 flex justify-center">
          <Button
            href="/quote/start?from=homepage"
            variant="solid"
            color="blue"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="ml-2.5">Get a price now</span>
          </Button>
        </div>
      </Container>
    </section>
  )
}
